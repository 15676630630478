textarea {
  font: 1em/1em 'Helvetica Neue', Helvetica, Arial, Tahoma, Verdana, sans-serif;
}

/**/
body {
  .header {
    .cartTop {
      visibility: visible;
      right:20px;
      top:94px;
      height:84px;
      position:absolute;
      z-index:102;
      margin:0 -3px 0 0;
      overflow:hidden;

      a {
        color:#4b121f;
      }
    }

    .cartTopContent {
      visibility: visible;
      background:#FFFFFF;
      box-shadow: 0 0 2px #444;
      padding:1em;
      font-size:130%;
      line-height:1.2em;
      margin:3px 3px 0;
      color:#4b121f;
      min-width:170px;

      .clearfix {
        > span {
          display:none;
        }
      }

      .cartTopIcon {
        @include cartIcon;

        display: block;
        text-decoration: none;

        span {
          position: relative;
          top: -4px;
        }
      }

      .checkout {
        @include checkout;

        font-size: 100%;
      }
    }

    .crumbs {
      visibility: visible;

      .crumbsContent {
        font-size:110%;
        line-height:1.1em;
        color:#787878;
      }

      a {
        color:#4b121f;
        text-decoration:none;
        border-bottom:1px solid #673641;
      }

      .crumbsArrow {
        @include sprite($crumbs_arrow);

        margin:0 0.25em 1px;
        display: inline-block;
      }
    }
  }

  .cart {
    .verifyFirst {
      margin-bottom: 1em;
      font-size: 14px;
    }

    .resending_pending {
      font-style: italic;
    }

    .resending_success {
      color: green;
    }

    .resending_error {
      color: red;
    }

    .cartNext {
      @include checkout;

      float: right;

      &:disabled {
        opacity: 0.5;
        cursor: default;
      }
    }
  }

  .noPicture {
    width:355px;
    height: 235px;
    background-color: #E0E0E0;
    position: relative;

    span {
      @include sprite($no-photo);

      position: absolute;
      top: 50%;
      left: 50%;
      display: block;
      margin-top: -32px;
      margin-left: -32px;
    }
  }

  .subCategories {
    margin-right:-1em;

    li {
      min-width:200px;
      width:22%;
      margin:0 1em 2em 0;
      float:left;
      text-align:center;

      .categoryPicture {
        width:200px;
        height:180px;
        overflow:hidden;
        position:relative;
        background: #E0E0E0;

        .noPhoto {
          @include sprite($no-photo);

          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          margin-top: -32px;
          margin-left: -32px;
          z-index: 1;
        }

        a {
          text-decoration: none;

          img {
            position: relative;
            z-index: 2;
          }
        }

        .mask {
          position:absolute;
          width:100%;
          height:100%;
          top:0;
          left:0;
          z-index: 5;
        }

        img {
          display:block;
        }
      }

      .text {
        background: #1F1F1F;
        bottom: 4px;
        color: #FFFFFF;
        font-size: 108%;
        left: 0;
        line-height: 1.2em;
        opacity: 0.9;
        padding: 0.5em 0.75em 0.75em;
        position: absolute;
        right: 0;
        text-align: center;
        z-index: 4;
      }

      .toolPrice {
        background: #FFFFFF;
        border-radius: 3px;
        color: #222222;
        font-size: 110%;
        line-height: 1em;
        opacity: 0.75;
        padding: 4px 6px;
        position: absolute;
        right: 9px;
        top: 8px;
        z-index: 4;
      }

      h3 {
        width:200px;
        font-size: 120%;
        line-height: 1.2em;
      }
    }
  }

  /**/
  .search {
    input[type="search"] {
      @include input_search;

      &:focus {
        border:1px solid #9d171d;
      }
    }

    button,
    button:hover {
      @include sprite($search);

      display: inline-block;
      background-color: transparent;
      width: 20px;
      height: 20px;
      border:0;
      padding:0;
      cursor:pointer;
      vertical-align: middle;
    }

    .searchForm {
      margin-bottom:2em;

      input[type="search"] {
        width:60%;
      }
    }

    h2 {
      padding:0 21px;
    }

    h3 {
      color:#6c6c6c;
      margin-bottom:0.5em;
    }
  }

  /**/
  .side {
    opacity: 1;
    height: auto;
    overflow: visible;

    * {
      visibility: visible;
    }

    .search {
      padding:1.5em 0 0 1.5em;

      input[type="search"] {
        width:120px;
      }
    }

    .menu {
      padding:1.8em 1.8em 0.8em;

      li {
        margin-bottom:1em;
        font-size:140%;
        line-height:1.2em;
        color:#4b121f;

        a {
          color:#4b121f;
          text-decoration:none;
          border-bottom:1px solid #4b121f;
        }

        &.active {
          background:#13007C;
          color:#FFFFFF;
        }
      }
    }
  }

  .content,
  .contentIndex {
    opacity: 1;
    height: auto;
    overflow: visible;

    * {
      visibility: visible;
    }
  }

  .contentIndex {
    padding:0 1.5em 1.5em;

    .cartIcon {
      @include cartIcon;

      margin-top: 2px;
    }

    .cartContent {
      .checkout {
        @include checkout;

        margin:0;
        float:none;
      }

      a {
        color:#4b121f;
      }
    }

    .search {
      float:left;
      margin-top:3px;
    }

    .cartContent {
      float:right;

      td {
        vertical-align:middle;
        padding-left:2em;
        font-size:130%;
      }
    }
  }

  .indexTop {
    border-bottom:1px solid #cdcdcd;
    margin:0 -1.5em 1em;
    padding:1em 1.5em;
  }

  /**/

  .page,
  .tool,
  .text,
  .search {
    h1 {
      font-size:210%;
      line-height:1.1em;
      margin-bottom:0.75em;
      color:#4b121f;
    }
  }

  .page,
  .tool,
  .search {
    font-size:120%;
    line-height:1.5em;
  }

  .text {
    font-size:140%;
    line-height:1.5em;

    h1 {
      font-size:190%;
    }

    h2 {
      font-size:130%;
      line-height:1.1em;
      margin-bottom:0.25em;
    }
  }

  .tool,
  .cart,
  .search,
  .feedback {
    h2 {
      background:#675d3b;
      font-size:140%;
      line-height:1.8em;
      color:#FFFFFF;
      display:inline-block;
      padding:0 1em 0 23px;
      margin:0 0 0.5em -20px;
    }
  }

  .cart {
    h2 {
      font-size: 18px;
      padding-left: 21px;
      line-height: 2.2em;
    }

    .switcher {
      font-size: 15px;
      margin-bottom: 1.5em;

      span {
        border-bottom: 1px dotted #1474c6;
        color: #1474c6;
        cursor: pointer;
      }
    }
  }

  .tool,
  .text {
    p {
      margin-bottom:1em;
    }

    ol {
      margin-left:2em;
      list-style-type: decimal;

      li {
        margin-bottom: 0.5em;
      }
    }
  }

  .tool {
    h2.subtitle {
      display:block;
      background:transparent;
      color:#000;
      font-size:100%;
      line-height:1.2em;
      padding:0;
      margin:0 0 0.5em 0;
      font-family:inherit;
    }

    .h3 {
      color:#6c6c6c;
      margin-bottom:1em;
      font-size: 130%;
    }
  }

  .page {
    a {
      color:#1474c6;
    }
  }

  .toolPhotos {
    position: absolute;
    top: 0;
    left: 0;
  }

  .toolRight {
    margin-left:355px;
    padding-left:2em;
  }

  .toolShortDescr {
    margin-bottom:2em;
  }

  .toolShortDescr p:last-child {
    margin-bottom:0;
  }

  .selectItem {
    margin-bottom:1em;
  }

  .price,
  .cart .price {
    float:left;
    font-size:2em;
    line-height:1.1em;
    color:#bf342e;
    margin-top:0.1em;
  }

  .cart .price {
    float:none;
    margin:0;
  }

  button,
  .addToCart,
  .addReview {
    background:#9c1616;
    /*font-family:Georgia;*/
    color:#FFFFFF;
    text-transform:uppercase;
    letter-spacing: 0.5px;
    height:2em;
    padding:0 1em;
    border-top:1px solid #c67979;
    border-right:1px solid #821212;
    border-bottom:1px solid #731010;
    border-left:1px solid #b14747;
    cursor:pointer;
  }

  button {
    font-size:90%;
    line-height: 1.8em;
  }

  .addToCart,
  .addReview {
    float:left;
    font-size:114%;
    margin-left: 2em;
  }

  .addReview {
    font-size: 95%;
    line-height: 2em;
    padding: 0 0.75em;
    text-decoration: none;
    margin: 0;
  }

  button:hover,
  .addToCart:hover,
  .addReview:hover,
  .header .cartTopContent .checkout:hover {
    background: #BF342E;
  }

  button:active,
  .addToCart:active,
  .addReview:active ,
  .header .cartTopContent .checkout:active {
    background:#9c1616;
  }

  .addToCartLoader {
    display: none;
    margin:8px 0 0 1em;
  }

  .inCart {
    float:left;
    margin-left:2em;
    line-height:2em;
    font-size:120%;
    font-style:italic;
    color:#6C6C6C;
  }


  .toolBody {
    font-size: 117%;
    line-height: 1.4em;
    margin-bottom:2em;
  }

  .siblingTools {
    margin-bottom: 2em;

    table {
      td {
        vertical-align:middle;
        height:40px;

        .thumbnail {
          display:block;
          width:60px;
          height:40px;
          background-color: #E0E0E0;
          position: relative;

          > span {
            @include sprite($no-photo-small);

            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            margin-top: -7.5px;
            margin-left: -7.5px;
            z-index: 1;
          }

          > img {
            position: relative;
            z-index: 2;
          }
        }
      }

      a {
        text-decoration:none;
        line-height:1.7em;

        &:not(.thumbnail){
          span {
            border-bottom:1px solid #1474c6;
          }
        }
      }
    }

    > div {
      width:250px;
      overflow:hidden;
    }

    > .prev {
      float:left;

      .thumbnail {
        margin-right:1.5em;
      }
    }

    > .next {
      float:right;

      .h3 {
        text-align:right;
      }

      table {
        float:right;

        td {
          text-align:right;
        }
      }

      .thumbnail {
        margin-left:1.5em;
      }
    }

    img {
      display: block;
    }
  }


  .reviews {
    li {
      margin-bottom:2em;

      .more {
        color:#1474c6;
        cursor:pointer;
        white-space:nowrap;

        .txt {
          border-bottom:1px dotted #1474c6;
        }

        .loader {
          display:inline-block;
          visibility:hidden;
          width:16px;
          height:16px;
          background:url(../../spinners/reviewLoader.gif);
          margin:0 0 -4px 2px;
        }
      }
    }
  }

  .reviewBody {
    width:99%;
    resize:none;
    font-size:110%;
    line-height: 1.4em;
  }

  .pictures {
    position:relative;
    width:355px;
    height:235px;
    margin-bottom:5px;

    li {
      position:absolute;
      left:0;
      top:0;
      width:355px;
      height:235px;
      background:#E0E0E0;

      img {
        display:block;
      }

      .zoom {
        @include sprite($zoom);

        position:absolute;
        bottom:5px;
        right:5px;
      }
    }
  }

  .thumbnailsGallery {
    position:relative;

    button {
      position:absolute;
      z-index:1;
      border:0;
      background:none;
      width: 35px;
      height:50px;
      cursor:pointer;
      padding: 0;

      &.disabled {
        display: none;
      }
    }

    .prev {
      top:7px;
      left:0;

      span {
        @include sprite($scrollable-left);

        display: block;
      }
    }

    .next {
      top:7px;
      right:0;

      span {
        @include sprite($scrollable-right);

        display: block;
      }
    }
  }

  .thumbnails {
    width:355px;
    height:67px;
    overflow:hidden;
    position:relative;

    ul {
      //width:10000em;
      position:absolute;
      display: flex;

      li {
        float:left;
        margin:0 3px 0 0;
        width:85px;
        height:65px;
        border:1px solid #FFFFFF;
        cursor:pointer;

        img {
          display:block;
        }

        &.active {
          border:1px solid #4B121F;
          cursor:default;
        }
      }
    }
  }

  /**/

  ul.categoriesIndex {
    padding-top:1em;
    margin-right:-1em;

    li {
      position: relative;

      .firstLevel {
        display:block;
        position:relative;
        width:210px;
        height:222px;
        text-decoration:none;

        img {
          display:block;

          &.mask {
            width:100%;
            height:100%;
            position:absolute;
            top:0;
            left:0;
            z-index:9;
          }
        }

        .title {
          position:absolute;
          width:100%;
          left:0;
          bottom:5px;
          z-index:8;
          background:#020202;
          opacity:0.65;
          font-size:180%;
          line-height:1.1em;
          height:2.1em;
          color:#FFFFFF;
          text-transform:uppercase;
          text-align:center;
          text-shadow:0 1px 1px #a9221d;
        }

        span.text {
          position:absolute;
          width:100%;
          left:0;
          bottom:8px;
          z-index:10;
          font-size:160%;
          line-height:1.1em;
          padding:0.5em 0;
          color:#FFFFFF;
          letter-spacing: 0.5px;
          text-transform:uppercase;
          text-align:center;
          text-shadow:0 1px 1px #000000;
        }
      }
    }

    > li {
      float:left;
      margin:0 1em 1em;
      min-width:210px;
      width:18%;

      ul {
        &.level-1 {
          margin-top:1em;
          width:210px;

          a {
            color:#414141;
          }

          li {
            &.hasChildren {
              > .arrow{
                border-bottom: 4px solid rgba(0, 0, 0, 0);
                border-left: 4px solid #414141;
                border-top: 4px solid rgba(0, 0, 0, 0);
                display: block;
                position: absolute;
                right: 5px;
                top: 50%;
                margin-top:-3px;
              }
            }

            &:hover {
              > ul {
                display: block;
              }
            }
          }

          > li {
            list-style:disc;
            margin:0 0 0.75em 2em;
            font-size:130%;
            line-height:1.3em;
            padding-right: 1em;

            ul {
              display: none;
              position: absolute;
              background: #f2f2f2;
              box-shadow: 0 1px 3px #666666;
              border-radius:5px;
              z-index: 30;
              top:0;
              left:100%;
              padding:0.5em 0;

              li {
                white-space: nowrap;
                padding: 0.5em 1em;
              }
            }

            > ul {
              margin-top: -1em;
            }

            &.hasChildren {
              > .arrow {
                display:inline-block;
                position:static;
                margin:0 -100% 0 0.1em;
              }
            }
          }
        }

        &.level-2 {
          li {
            &.hasChildren {
              padding-right:1.5em;
            }
          }
        }
      }
    }
  }

  /**/

  .searchResults {
    margin-left:40px;
    padding-left:1.5em;

    .hightlightParent {
      font-size:90%;
    }

    .highlight {
      background:#f0efa5;
    }

    li {
      position:relative;
      min-height:30px;
      margin-bottom:1em;
      font-size:110%;
      list-style: none;

      a {
        font-size:110%;
      }

      .thumbnail {
        position:absolute;
        left:-1.5em;
        top:2px;
        margin-left:-36px;
        width:40px;
        height:30px;
        background-color: #E0E0E0;

        span {
          @include sprite($no-photo-small);

          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          margin-top: -7.5px;
          margin-left: -7.5px;
          z-index: 1;
        }

        img {
          position: relative;
          z-index: 2;
        }
      }
    }
  }

  /**/

  .cartTable {
    max-width:750px;
    margin-left:-0.5em;

    tr {
      th {
        vertical-align: middle;
        padding:0.5em;
        font-size:130%;
        text-align: left;
        font-weight:normal;
        border-bottom: 1px solid #999;
        font-style: italic;
      }

      td {
        vertical-align: middle;
        padding:0.5em;
        font-size:130%;
      }

      .thumbnail {
        position:absolute;
        top:0;
        left:0;
        width:40px;
        height:30px;
        background-color: #E0E0E0;

        span {
          @include sprite($no-photo-small);

          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          margin-top: -7.5px;
          margin-left: -7.5px;
          z-index: 1;
        }

        img {
          position: relative;
          z-index: 2;
        }
      }

      .loading {
        display:none;
      }

      &.total {
        td {
          background:#F0F0F0;
        }
      }
    }
  }

  .removeFromCart {
    cursor: pointer;
    color:#BF342E;
    border-bottom: 1px dotted #BF342E;
    font-size:80%;
  }

  /**/

  .profile {
    tr {
      td {
        vertical-align: top;
        padding-bottom:0.5em;

        input[type="text"],
        input[type="email"] {
          width:25em;

          &.number {
            width: 4em;
          }
        }

        textarea {
          height:5em;
          width:23em;
        }

        input {
          &.appt {
            width:4em;
          }

          &.zip {
            width:7em;
          }
        }

        &:first-child {
          padding-right:2em;
        }
      }
    }
  }

  /**/

  .orderStatusPlaced {
    color:#666666;
  }

  .orderStatusConfirmed,
  .orderStatusShipped {
    color:green;
  }

  .orderStatusPayment_received {
    color:#BF342E;
  }

  .orderDetails {
    box-sizing: border-box;
    margin: 0;
    max-width: 740px;
    padding: 0.7em;
    resize: none;
    width: 100%;
  }

  .orderHowFound {
    margin-bottom: 1em;

    input[type="radio"] {
      margin: 0 3px 2px 0;
    }

    label {
      margin-left: 0.2em;

      &:after {
        content: "";
        display: block;
        height: 0.3em;
      }
    }

    input#order_details_how_found_placeholder,
    label[for="order_details_how_found_placeholder"] {
      display: none;
    }
  }

  /**/


  .feedback {
    font-size:90%;
    padding-top:1em;

    table {
      tr {
        td {
          vertical-align: top;
          padding:0.25em 0;

          &:first-child {
            padding-right:2em;
            min-width:5em;
          }
        }
      }
    }

    input {
      width:30em;
      font-size:100%;
    }

    textarea {
      width:40em;
      font-size:100%;
    }

    button {
      margin-left:7.8em;
    }

    .flash-notice {
      margin-bottom:1em;
    }
  }


  /**/

  .suzukitool_user_registration_register td,
  .loginForm td {
    padding:0.5em 1em 0.5em 0;
  }

  .suzukitool_user_registration_register tr td:first-child {
    width:10em;
  }

  .suzukitool_user_registration_register td input {
    width:20em;
  }

  .error {
    color:red;
  }

  .flash-notice {
    margin-bottom:1em;
    color:green;
  }

  .flash-error {
    margin-bottom:1em;
    color:red;
  }

  /**/
  .toolTop {
    margin-bottom:1.5em;
    min-height: 235px;
    position: relative;
  }

  .oauth {
    padding-top: 1em;

    .gsi-material-button {
      -moz-user-select: none;
      -webkit-user-select: none;
      -ms-user-select: none;
      -webkit-appearance: none;
      background-color: WHITE;
      background-image: none;
      border: 1px solid #747775;
      -webkit-border-radius: 4px;
      border-radius: 4px;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      color: #1f1f1f;
      cursor: pointer;
      font-family: 'Roboto', arial, sans-serif;
      font-size: 14px;
      height: 40px;
      letter-spacing: 0.25px;
      outline: none;
      overflow: hidden;
      padding: 0 12px;
      position: relative;
      text-align: center;
      -webkit-transition: background-color .218s, border-color .218s, box-shadow .218s;
      transition: background-color .218s, border-color .218s, box-shadow .218s;
      vertical-align: middle;
      white-space: nowrap;
      width: auto;
      max-width: 400px;
      min-width: min-content;
    }

    .gsi-material-button .gsi-material-button-icon {
      height: 20px;
      margin-right: 12px;
      min-width: 20px;
      width: 20px;
    }

    .gsi-material-button .gsi-material-button-content-wrapper {
      -webkit-align-items: center;
      align-items: center;
      display: flex;
      -webkit-flex-direction: row;
      flex-direction: row;
      -webkit-flex-wrap: nowrap;
      flex-wrap: nowrap;
      height: 100%;
      justify-content: space-between;
      position: relative;
      width: 100%;
    }

    .gsi-material-button .gsi-material-button-contents {
      -webkit-flex-grow: 1;
      flex-grow: 1;
      font-family: 'Roboto', arial, sans-serif;
      font-weight: 500;
      overflow: hidden;
      text-overflow: ellipsis;
      vertical-align: top;
    }

    .gsi-material-button .gsi-material-button-state {
      -webkit-transition: opacity .218s;
      transition: opacity .218s;
      bottom: 0;
      left: 0;
      opacity: 0;
      position: absolute;
      right: 0;
      top: 0;
    }

    .gsi-material-button:disabled {
      cursor: default;
      background-color: #ffffff61;
      border-color: #1f1f1f1f;
    }

    .gsi-material-button:disabled .gsi-material-button-contents {
      opacity: 38%;
    }

    .gsi-material-button:disabled .gsi-material-button-icon {
      opacity: 38%;
    }

    .gsi-material-button:not(:disabled):active .gsi-material-button-state,
    .gsi-material-button:not(:disabled):focus .gsi-material-button-state {
      background-color: #303030;
      opacity: 12%;
    }

    .gsi-material-button:not(:disabled):hover {
      -webkit-box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .30), 0 1px 3px 1px rgba(60, 64, 67, .15);
      box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .30), 0 1px 3px 1px rgba(60, 64, 67, .15);
    }

    .gsi-material-button:not(:disabled):hover .gsi-material-button-state {
      background-color: #303030;
      opacity: 8%;
    }

    //p {
    //  float: left;
    //
    //  a {
    //    display: block;
    //  }
    //
    //  &.facebookLogin {
    //    margin: 0 1em 0.5em 0;
    //
    //    a {
    //      @include sprite($facebook-login);
    //    }
    //  }
    //
    //  &.googleLogin {
    //    margin: -4px 0 0.5em -4px;
    //
    //    a {
    //      @include sprite($google-login-normal);
    //    }
    //  }
    //}
  }
}
